import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const steps = [
    { title: "Tech Architecture", description: "We architect your product for scale and power" },
    { title: "Database Design", description: "We optimize your data for lightning-fast retrieval" },
    { title: "AI and Machine Learning Integration", description: "We integrate cutting-edge AI for intelligent features" },
    { title: "Frontend Development", description: "We build beautiful, engaging user interfaces" },
    { title: "Backend Development", description: "We power your product with robust backend development" },
    { title: "Third-party Integrations", description: "We connect your product to the tools it needs" },
    { title: "Quality Assurance and Testing", description: "We rigorously test your product for a flawless user experience" },
    { title: "Deployment", description: "We ensure a seamless deployment to your users" },
  ];

const MobilePowerSteps = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="bg-gray-100 p-4 font-sans">
      <motion.h1 
        className="text-2xl font-bold text-center mb-6"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        We build products that are beautiful on the outside...
      </motion.h1>
      <div className="space-y-3">
        {steps.map((step, index) => (
          <motion.div 
            key={index}
            className="relative bg-white rounded-lg shadow-sm p-4 text-center transition-all duration-300 ease-in-out"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            onHoverStart={() => setHoveredIndex(index)}
            onHoverEnd={() => setHoveredIndex(null)}
            whileHover={{ zIndex: 10 }}
          >
            <h2 className="font-bold text-base mb-1">{step.title}</h2>
            <p className="text-sm text-gray-600">{step.description}</p>
            <AnimatePresence>
              {hoveredIndex === index && (
                <motion.div
                  className="absolute inset-0 bg-blue-500 rounded-lg shadow-lg"
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="p-4 text-white text-center">
                    <h2 className="font-bold text-base mb-1">{step.title}</h2>
                    <p className="text-sm">{step.description}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MobilePowerSteps;