import React from "react";
import TopHeading from "../components/services/TopHeading";
import Efficiency from "../components/services/Efficiency";
import TechStack from "../components/services/TechStack";


import ResponsiveProductSteps from "../components/services/resposiveBeauty"
import ResponsivePowerSteps from "../components/services/responsivePower";
const Services = () => {
  return (
    <div>
      <TopHeading />
   

      <ResponsiveProductSteps/>
      <ResponsivePowerSteps/>
      <Efficiency />
      <TechStack />
    </div>
  );
};

export default Services;
