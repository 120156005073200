import React from "react";
import Breadcrumb from "../components/gratify/Breakcrumb";
import BackToTop from "../components/gratify/BackToTop";
import FeaturesSection from "../components/gratify/interactivejourney";
import Gratifyscales from "../components/gratify/gratifyscales";
import TransformSection from "../components/gratify/readytotransform";
import GratifySection from "../components/gratify/wholovesgratify";
import GratifyPartner from "../components/gratify/gratifysection";
import Card from "../components/gratify/Card";

const Gratify = () => {
  return (
    <div >
      <Breadcrumb />
      <FeaturesSection />
      <Gratifyscales />
      <TransformSection />
      <GratifySection />
      <Card />
      <GratifyPartner />
      <BackToTop />
    </div >
  );
};

export default Gratify;
