import React from "react";
import gratify from "../../assets/project/gratify.svg";
import esto from "../../assets/project/esto.svg";
import pyzo from "../../assets/project/pyzo.png";
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom'
const cards2 = [
  {
    imageUrl: gratify,
    heading: "Gratify",
    subheading: "Rewards & Gamification SaaS",
    route: "/products/gratify"
  },
  {
    imageUrl: esto,
    heading: "Esto",
    subheading: "Smart Marketing & Sales SaaS",
    route: "/products/esto"
  },
  // {
  //   imageUrl: pyzo,
  //   heading: "Pyzo",
  //   subheading: "Conversational AI SaaS",
  //   route: "/products/pyzo"
  // },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 770,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.07,
        slidesToScroll: 1,
        initialSlide: 1.5,
      },
    },
  ],
};

const SoftwareAsService = () => {
  const navigate = useNavigate()
  return (
    <div>
      <div className="hidden lg:grid gap-[100px] grid-cols-2 sm:grid-cols-3">
        {cards2.map((card, index) => (
          <div
            key={index}
            className="rounded-lg overflow-hidden hover:scale-105 aspect-w-1 aspect-h-1"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(card.route)}
          >
            <img
              className="w-full h-auto rounded-lg mb-4"
              src={card.imageUrl}
              alt="Image"
            />
            <h3
              className="text-xl font-bold mb-2 overflow-hidden text-[#0F0F0F]"
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.heading}
            </h3>
            <p
              className="text-[#0F0F0F] mb-8 text-lg overflow-hidden"
              style={{
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                display: "-webkit-box",
              }}
            >
              {card.subheading}
            </p>
          </div>
        ))}
      </div>

      <div className=" text-left gap-5 m-auto block lg:hidden">
        <Slider {...settings}>
          {cards2.map((card, index) => (
            <div key={index} className="rounded-lg overflow-hidden w-full px-5" style={{ cursor: "pointer" }}
              onClick={() => navigate(card.route)}>
              <img
                className=" h-auto rounded-lg mb-4 mx-auto w-11/12"
                // style={{ width: "95%"}}
                src={card.imageUrl}
                alt="Image"
              />
              <h3
                className="text-xl font-bold mb-2 overflow-hidden ms-3 lg:ms-4"
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                }}
              >
                {card.heading}
              </h3>
              <p
                className="text-gray-700 mb-8 text-lg overflow-hidden ms-3 lg:ms-4"
                style={{
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  display: "-webkit-box",
                }}
              >
                {card.subheading}
              </p>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default SoftwareAsService;
