import React from "react";
import java from "../../assets/svg/java.svg";
import node from "../../assets/svg/node.svg";
import python from "../../assets/svg/python.svg";
import django from "../../assets/svg/django.svg";
import chatGpt from "../../assets/svg/chatgpt.svg";
import bhashini from "../../assets/svg/bhashini.svg";
import flutter from "../../assets/svg/flutter.svg";
import react1 from "../../assets/svg/react1.svg";
import react2 from "../../assets/svg/react2.svg";
import vue from "../../assets/svg/vue.svg";
import next from "../../assets/svg/next.svg";
import swift from "../../assets/svg/swift.svg";
import kotlin from "../../assets/svg/kotlin.svg";
import awslambda from "../../assets/svg/awslambda.svg";
import ios from "../../assets/svg/ios.svg";
import github from "../../assets/svg/github.svg";
import gitlab from "../../assets/svg/gitlab.svg";
import microsoft from "../../assets/svg/microsoftazure.svg";
import redis from "../../assets/svg/redis.svg";
import postman from "../../assets/svg/postman.svg";
import redux from "../../assets/svg/redux.svg";
import elasticsearch from "../../assets/svg/elasticsearch.svg";
import w from "../../assets/svg/w.svg";
import aws from "../../assets/svg/aws.svg";
import mysql from "../../assets/svg/mysql.svg";
import mongodb from "../../assets/svg/mongodb.svg";
import { motion } from "framer-motion";

const techImgs = [
  microsoft,
  ios,
  elasticsearch,
  mongodb,
  awslambda,
  w,
  github,
  aws,
  postman,
  redis,
  mysql,
  gitlab,
  redux,
];

const TechStack = () => {
  return (
    <>
      <div className="mb-10 w-[95%] lg:w-[90%]  m-auto">
        <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center ">
          We have had enough of one-size-fits-all
        </h1>
        <h1 className="font-Outfit #272727 font-bold text-2xl lg:text-5xl text-center ">
          solutions. Choose the right tech stack with us
        </h1>

        <div className="flex gap-[8px] lg:gap-12 m-auto justify-center lg:justify-between mt-12 flex-wrap">
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <div className=" box-border cursor-pointer w-[160px] lg:w-[270px] flex flex-col justify-between p-[12px] lg:p-[29px] bg-[#F7F7F7] border-b-[3px] border-[gray] rounded-3xl">
              <div className="flex flex-col justify-between h-[100px] lg:h-[150px]">
                <div className="flex justify-between items-center">
                  <img src={java} alt="java" className="lg:h-[52px] h-[32.62px] w-[53px] lg:w-[84px]" />
                  <img src={django} alt="django" className="lg:h-[32px]  h-[22px] w-[59.59px] lg:w-[95px]" />
                </div>
                <div className="flex justify-between items-center ">
                  <div className="h-[46px] w-[46px]">
                    <img src={python} alt="python" className="lg:h-[46px]  h-[29px] w-[29px] lg:w-[46px]" />
                  </div>
                  <img src={node} alt="node" className="lg:h-[33px]  h-[22px] w-[78px] lg:w-[120px]" />
                </div>
              </div>
              <div>
                <div className="border-[1px] border-[#D4D6D8] mt-[50px]"></div>
                <div className="mt-[15px] text-center text-[#272727] font-medium text-[16px] lg:text-[22px]">
                  Backend
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <div className=" w-[160px] lg:w-[270px] cursor-pointer box-border flex flex-col justify-between  p-[12px] lg:p-[29px] bg-[#F7F7F7] border-b-[3px] border-[gray] rounded-3xl">
              <div className="flex flex-col justify-between h-[100px] lg:h-[150px]">
                <div className="flex justify-between items-center ">
                  <img src={chatGpt} alt="chatGPT" className="lg:h-[65px]  h-[41px] w-[124px] lg:w-[199px]" />
                </div>
                <div className="flex justify-between items-center ">
                  <img src={bhashini} alt="bhashini" className="lg:h-[55px]  h-[34.62px] w-[132.35px] lg:w-[211px]" />
                </div>
              </div>
              <div>
                <div className="border-[1px] border-[#D4D6D8] mt-[50px]"></div>
                <div className="mt-[15px] text-center text-[#272727] font-medium text-[16px] lg:text-[22px]">
                  Gen AI
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <div className="w-[160px] lg:w-[270px] cursor-pointer p-[12px] lg:p-[29px] flex flex-col justify-between bg-[#F7F7F7] border-b-[3px] border-[gray] rounded-3xl">
              <div className="flex flex-col justify-between h-[100px] lg:h-[150px]">
                <div className="flex justify-between items-center ">
                  <img src={react1} alt="react" className="lg:h-[43px]  h-[28.23px] w-[31.36px] lg:w-[52px]" />
                  <img src={flutter} alt="flutter" className="lg:h-[37px]  h-[23.21px] w-[80px] lg:w-[125px]" />
                </div>
                <div className="flex justify-between items-center ">
                  <img src={kotlin} alt="kotlin" className="lg:h-[27px]  h-[17px] w-[68px]  lg:w-[108px]" />
                  <img src={swift} alt="swift" className="lg:h-[36px]  h-[22.58px] w-[64px] lg:w-[102px]" />
                </div>
              </div>
              <div>
                <div className="border-[1px] border-[#D4D6D8] mt-[50px]"></div>
                <div className="mt-[15px] text-center text-[#272727] font-medium text-[16px] lg:text-[22px]">
                  Apps
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            <div className="w-[160px] lg:w-[270px] cursor-pointer  p-[12px] lg:p-[29px] flex flex-col justify-between bg-[#F7F7F7] border-b-[3px] border-[gray] rounded-3xl">
              <div className="flex flex-col justify-between h-[100px] lg:h-[150px]">
                <div className="flex justify-between items-center ">
                  <img src={next} alt="next" className="lg:h-[78px]  h-[80.20px] w-[49px] lg:w-[128px]" />
                </div>
                <div className="flex justify-between items-center ">
                  <img src={react2} alt="react" className="lg:h-[43px]  h-[28.23px] w-[81.55px] lg:w-[129px]" />
                  <img src={vue} alt="vue" className="lg:h-[51px]  h-[32px] w-[32px] lg:w-[51px]" />
                </div>
              </div>
              <div>
                <div className="border-[1px] border-[#D4D6D8] mt-[50px]"></div>
                <div className="mt-[15px] text-center text-[#272727] font-medium text-[16px] lg:text-[22px]">
                  Web
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <div className="flex flex-wrap gap-12 justify-center lg:justify-between items-center m-auto mt-[80px] mb-[140px]">
          {techImgs.map((item) => {
            return <img src={item} className="max-h-[24px] lg:max-h-[45px] " alt="icon" />;
          })}
        </div>
      </div>
      <div className="border-b-[1px] border-[#9A5D5D]"></div>
    </>
  );
};

export default TechStack;
