import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Services from '../pages/Services'
import Products from '../pages/Products'
import Gratify from '../pages/Gratify'

const AllRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/services' element={<Services/>}></Route>
        <Route path='/products' element={<Products/>}></Route>
        <Route path='/products/gratify' element={<Gratify/>}></Route>
        <Route></Route>
        <Route></Route>
    </Routes>
  )
}

export default AllRoutes