// // import React, { useState } from "react";
// // import { isValidForm } from "../../utils/validation";
// // import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// // import Create1 from "../../assets/img/Create1.png";
// // const Contact = () => {
// //   const [values, setValues] = useState({
// //     requirement: "",
// //     name: "",
// //     email: "",
// //     phone: "",
// //   });
// //   const [error, setError] = useState({
// //     requirement: "",
// //     name: "",
// //     email: "",
// //     phone: "",
// //   });

// //   const handleChange = (key, value) => {
// //     if (error[key]) {
// //       setError((pre) => {
// //         return {
// //           ...pre,
// //           [key]: "",
// //         };
// //       });
// //     }
// //     setValues((pre) => {
// //       return {
// //         ...pre,
// //         [key]: value,
// //       };
// //     });
// //   };

// //   const handleSubmit = () => {
// //     if (!isValidForm(values, setError)) {
// //       console.log("form submitted", values);
// //       // alert("form submitted");
// //     }
// //   };

// //   return (
// //     <div className="bg-[#538CFF]	p-5 lg:p-10  my-10 ">
// //       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
// //         <img
// //           src={Create1}
// //           alt="create"
// //           className="h-[9vh] mb-4"
// //         />
// //         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
// //           Building tech is hard, but with us, it's Magic
// //         </span>
// //       </div>

// //       <div className="">
// //         <div className="w-full  md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
// //           {/* <div className="relative  "> */}
// //           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-[#FFFFFF] w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px]
// //                 lg:mb-2 cursor-pointer text-[18px]   border-white active:outline-none
// //                 focus:outline-none focus:border-none focus:ring-0 active:border-none  "
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //             >
// //               <option value="" disabled hidden>
// //                 I Want To
// //               </option>
// //               <option value="build-a-product">Build a Product</option>
// //               <option value="get-hired">Get Hired</option>
// //               <option value="leverage-saas">Leverage SaaS</option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div> */}

// //           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2  border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //               style={{
// //                 color: values.requirement ? "#FFFFFF" : "#000000",
// //                 backgroundColor: "#5A91FF",
// //               }}
// //             >
// //               <option value="" disabled hidden style={{ text: "#FFFFFF" }}>
// //                 I Want To
// //               </option>
// //               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Build a Product
// //               </option>
// //               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Get Hired
// //               </option>
// //               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage SaaS
// //               </option>
// //               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Consult an Expert
// //               </option>
// //               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage AI Solutions
// //               </option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div> */}
// //           <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
// //             <select
// //               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
// //               value={values.requirement}
// //               onChange={(e) => handleChange("requirement", e.target.value)}
// //               style={{
// //                 backgroundColor: "#5A91FF",
// //                 color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF", // Ensure color is applied correctly
// //               }}
// //             >
// //               <option
// //                 value=""
// //                 disabled
// //                 hidden
// //                 style={{
// //                   color: "#FFFFFF", // Ensure placeholder text is white
// //                 }}
// //               >
// //                 I Want To
// //               </option>
// //               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Build a Product
// //               </option>
// //               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Get Hired
// //               </option>
// //               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage SaaS
// //               </option>
// //               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Consult an Expert
// //               </option>
// //               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
// //                 Leverage AI Solutions
// //               </option>
// //             </select>
// //             {error.requirement && (
// //               <div className="text-[#FFFFFF] text-[12px]">
// //                 {error.requirement}
// //               </div>
// //             )}
// //           </div>

// //           {/* </div> */}
// //           {/* <div className="w-[100%] lg:max-w-[460px]">
// //             <input
// //               className="block bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
// //               text-[18px] w-[100%] lg:w-[460px] py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]
// //                  lg:mb-2 rounded-md  border-white border-b-2 active:outline-none
// //                 focus:outline-none focus:border-b-2 focus:ring-0 active:border-none
// //                  placeholder-gray-900"
// //               type="text"
// //               placeholder="Name"
// //               style={{ outline: "none" }}
// //               value={values.name}
// //               onChange={(e) => handleChange("name", e.target.value)}
// //             />
// //             {error.name && (
// //               <div className="text-[#FFFFFF] text-[12px]">{error.name}</div>
// //             )}
// //           </div> */}
// //           {/* <div className="w-[100%] lg:max-w-[460px]">
// //             <input
// //               className="block active:outline-none focus:outline-none w-[100%]
// //               text-[18px]
// //                lg:w-[460px] focus:border-b-2 focus:ring-0 active:border-none
// //                  bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
// //                   py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]  lg:mb-2  rounded-md
// //                     border-white border-b-2 "
// //               type="email"
// //               placeholder="Email Address"
// //               value={values.email}
// //               onChange={(e) => handleChange("email", e.target.value)}
// //             />
// //             {error?.email && (
// //               <div className="text-[#FFFFFF] text-[12px]">{error?.email}</div>
// //             )}
// //           </div> */}
// //           <div className="w-[100%] lg:max-w-[460px]">
// //             <PhoneNumberInput
// //               value={values.phone}
// //               onchange={(value) => handleChange("phone", value)}
// //             />
// //             {error?.phone && (
// //               <div className="text-[#FFFFFF] text-[12px] mt-[8px]">
// //                 {error?.phone}
// //               </div>
// //             )}
// //           </div>
// //         </div>

// //         <button
// //           onClick={handleSubmit}
// //           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
// //           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
// //           rounded-3xl flex justify-center items-center hover:opacity-95 transition
// //           duration-300 ease-in-out m-auto gap-6"
// //         >
// //           Submit
// //         </button>
// //       </div>

// //       {showThankYou && (
// //         <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
// //           <div className="bg-white p-6 rounded-lg shadow-lg text-center">
// //             <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
// //             <p>Your submission has been received.</p>
// //           </div>
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default Contact;

// import React, { useState } from "react";
// import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// import Create1 from "../../assets/img/Create1.png";

// const Contact = () => {
//   const [values, setValues] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [error, setError] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [showThankYou, setShowThankYou] = useState(false);

//   const handleChange = (key, value) => {
//     if (error[key]) {
//       setError((prev) => ({
//         ...prev,
//         [key]: "",
//       }));
//     }
//     setValues((prev) => ({
//       ...prev,
//       [key]: value,
//     }));
//   };

//   const handleSubmit = () => {
//     let hasError = false;
//     const newError = { ...error };

//     // Check if phone number is filled
//     if (!values.phone) {
//       newError.phone = "Phone number is required.";
//       hasError = true;
//     } else {
//       newError.phone = "";
//     }

//     // Check if requirement is selected
//     if (!values.requirement) {
//       newError.requirement = "This field is required.";
//       hasError = true;
//     } else {
//       newError.requirement = "";
//     }

//     setError(newError);

//     if (!hasError) {
//       console.log("form submitted", values);
//       setShowThankYou(true);

//       // Reset form after submission
//       setValues({
//         requirement: "",
//         name: "",
//         email: "",
//         phone: "",
//       });

//       // Hide thank you message after 3 seconds
//       setTimeout(() => {
//         setShowThankYou(false);
//       }, 3000);
//     }
//   };

//   return (
//     <div className="bg-[#538CFF] p-5 lg:p-10 my-10 relative overflow-hidden">
//       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
//         <img
//           src={Create1}
//           alt="create"
//           className=" h-[5vh] xl:h-[6vh] mb-4"
//         />
//         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
//           Building tech is hard, but with us, it's Magic
//         </span>
//       </div>

//       <div >
//         <div className="w-full md:mt-[72px] flex flex-wrap gap-6 justify-start items-start mb-6 pl-[30px]  ">
//           <div className="w-full lg:max-w-[460px] flex  item-center   ">
//             {/* <div className="bg-[#5A91FF] w-[90%] sm:w-[60%] lg:w-[400px]  rounded-md  border-b-2 border-white">
//               <select
//                 className="block  text-white  pr-[70px]  sm:pr-[170px] lg:pr-[170px]   m-auto py-[14px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0 rounded-3xl"
//                 value={values.requirement}
//                 onChange={(e) => handleChange("requirement", e.target.value)}
//                 style={{
//                   backgroundColor: "#5A91FF",
//                   color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
//                 }}
//               >
//                 <option
//                   value=""
//                   disabled
//                   hidden
//                   style={{
//                     color: "#FFFFFF",
//                   }}
//                 >
//                   I Want To
//                 </option>
//                 <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Build a Product
//                 </option>
//                 <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Get Hired
//                 </option>
//                 <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage SaaS
//                 </option>
//                 <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Consult an Expert
//                 </option>
//                 <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage AI Solutions
//                 </option>
//               </select>
//             </div> */}
//             <div className="bg-[#5A91FF] w-[90%] sm:w-[60%] md:w-[40%] md:pr-[90px] lg:w-[400px] rounded-md border-b-2 border-white">
//               <select
//                 className="block text-white  m-auto py-[14px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0 rounded-3xl"
//                 value={values.requirement}
//                 onChange={(e) => handleChange("requirement", e.target.value)}
//                 style={{
//                   backgroundColor: "#5A91FF",
//                   color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
//                   paddingRight: "70px",
//                 }}
//               >
//                 <option value="" disabled hidden style={{ color: "#FFFFFF" }}>
//                   I Want To
//                 </option>
//                 <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Build a Product
//                 </option>
//                 <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Get Hired
//                 </option>
//                 <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage SaaS
//                 </option>
//                 <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Consult an Expert
//                 </option>
//                 <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                   Leverage AI Solutions
//                 </option>
//               </select>
//             </div>

//             <style jsx>{`
//   @media (max-width: 370px) {
//     select {
//       padding-right: 30px !important;
//     }
//   }
// `}</style>

//             {/* Display error message directly below the select */}
//             {error.requirement && (
//               <div className="text-[#FF0000] text-[12px] mt-1">
//                 {error.requirement}
//               </div>
//             )}
//           </div>

//           <div className="w-[90%] flex item-start  sm:w-[60%] md:w-[0%] lg:w-[400px]  ">
//             <PhoneNumberInput
//               value={values.phone}
//               onchange={(value) => handleChange("phone", value)}
//             />
//             {/* Display error message directly below the phone input */}
//             {error.phone && (
//               <div className="text-[#FF0000] text-[12px] mt-1">
//                 {error.phone}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           onClick={handleSubmit}
//           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
//           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
//           rounded-3xl flex justify-center items-center hover:opacity-95 transition
//           duration-300 ease-in-out m-auto gap-6"
//         >
//           Submit
//         </button>

//         {/* Thank you message placed below the submit button */}
//         {showThankYou && (
//           <div className="mt-4 text-center text-[#FFFFFF]  p-4 rounded-md">
//             Thank you for your submission!
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Contact;

// import React, { useState } from "react";
// import { isValidForm } from "../../utils/validation";
// import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
// import Create1 from "../../assets/img/Create1.png";
// const Contact = () => {
//   const [values, setValues] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });
//   const [error, setError] = useState({
//     requirement: "",
//     name: "",
//     email: "",
//     phone: "",
//   });

//   const handleChange = (key, value) => {
//     if (error[key]) {
//       setError((pre) => {
//         return {
//           ...pre,
//           [key]: "",
//         };
//       });
//     }
//     setValues((pre) => {
//       return {
//         ...pre,
//         [key]: value,
//       };
//     });
//   };

//   const handleSubmit = () => {
//     if (!isValidForm(values, setError)) {
//       console.log("form submitted", values);
//       // alert("form submitted");
//     }
//   };

//   return (
//     <div className="bg-[#538CFF]	p-5 lg:p-10  my-10 ">
//       <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
//         <img
//           src={Create1}
//           alt="create"
//           className="h-[9vh] mb-4"
//         />
//         <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
//           Building tech is hard, but with us, it's Magic
//         </span>
//       </div>

//       <div className="">
//         <div className="w-full  md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
//           {/* <div className="relative  "> */}
//           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-[#FFFFFF] w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px]
//                 lg:mb-2 cursor-pointer text-[18px]   border-white active:outline-none
//                 focus:outline-none focus:border-none focus:ring-0 active:border-none  "
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//             >
//               <option value="" disabled hidden>
//                 I Want To
//               </option>
//               <option value="build-a-product">Build a Product</option>
//               <option value="get-hired">Get Hired</option>
//               <option value="leverage-saas">Leverage SaaS</option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div> */}

//           {/* <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2  border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//               style={{
//                 color: values.requirement ? "#FFFFFF" : "#000000",
//                 backgroundColor: "#5A91FF",
//               }}
//             >
//               <option value="" disabled hidden style={{ text: "#FFFFFF" }}>
//                 I Want To
//               </option>
//               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Build a Product
//               </option>
//               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Get Hired
//               </option>
//               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage SaaS
//               </option>
//               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Consult an Expert
//               </option>
//               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage AI Solutions
//               </option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div> */}
//           <div className="w-[100%] bg-[#5A91FF] rounded-md border-b-2 mt-[-6px] border-white lg:max-w-[460px]">
//             <select
//               className="block bg-[#5A91FF] text-white w-[95%] lg:w-[430px] pl-[14px] py-[14px] lg:py-[14px] lg:mb-2 cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
//               value={values.requirement}
//               onChange={(e) => handleChange("requirement", e.target.value)}
//               style={{
//                 backgroundColor: "#5A91FF",
//                 color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF", // Ensure color is applied correctly
//               }}
//             >
//               <option
//                 value=""
//                 disabled
//                 hidden
//                 style={{
//                   color: "#FFFFFF", // Ensure placeholder text is white
//                 }}
//               >
//                 I Want To
//               </option>
//               <option value="build-a-product" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Build a Product
//               </option>
//               <option value="get-hired" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Get Hired
//               </option>
//               <option value="leverage-saas" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage SaaS
//               </option>
//               <option value="consult-expert" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Consult an Expert
//               </option>
//               <option value="leverage-ai-solutions" style={{ color: "#000000", backgroundColor: "#FFFFFF" }}>
//                 Leverage AI Solutions
//               </option>
//             </select>
//             {error.requirement && (
//               <div className="text-[#FFFFFF] text-[12px]">
//                 {error.requirement}
//               </div>
//             )}
//           </div>

//           {/* </div> */}
//           {/* <div className="w-[100%] lg:max-w-[460px]">
//             <input
//               className="block bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
//               text-[18px] w-[100%] lg:w-[460px] py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]
//                  lg:mb-2 rounded-md  border-white border-b-2 active:outline-none
//                 focus:outline-none focus:border-b-2 focus:ring-0 active:border-none
//                  placeholder-gray-900"
//               type="text"
//               placeholder="Name"
//               style={{ outline: "none" }}
//               value={values.name}
//               onChange={(e) => handleChange("name", e.target.value)}
//             />
//             {error.name && (
//               <div className="text-[#FFFFFF] text-[12px]">{error.name}</div>
//             )}
//           </div> */}
//           {/* <div className="w-[100%] lg:max-w-[460px]">
//             <input
//               className="block active:outline-none focus:outline-none w-[100%]
//               text-[18px]
//                lg:w-[460px] focus:border-b-2 focus:ring-0 active:border-none
//                  bg-[#5A91FF] text-[#FFFFFF] placeholder:text-[#FFFFFF]
//                   py-[14px] px-[14px] lg:py-[18px] lg:px-[14px]  lg:mb-2  rounded-md
//                     border-white border-b-2 "
//               type="email"
//               placeholder="Email Address"
//               value={values.email}
//               onChange={(e) => handleChange("email", e.target.value)}
//             />
//             {error?.email && (
//               <div className="text-[#FFFFFF] text-[12px]">{error?.email}</div>
//             )}
//           </div> */}
//           <div className="w-[100%] lg:max-w-[460px]">
//             <PhoneNumberInput
//               value={values.phone}
//               onchange={(value) => handleChange("phone", value)}
//             />
//             {error?.phone && (
//               <div className="text-[#FFFFFF] text-[12px] mt-[8px]">
//                 {error?.phone}
//               </div>
//             )}
//           </div>
//         </div>

//         <button
//           onClick={handleSubmit}
//           className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit
//           text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF]
//           rounded-3xl flex justify-center items-center hover:opacity-95 transition
//           duration-300 ease-in-out m-auto gap-6"
//         >
//           Submit
//         </button>
//       </div>

//       {showThankYou && (
//         <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg text-center">
//             <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
//             <p>Your submission has been received.</p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Contact;

import React, { useState, useEffect } from "react";
import PhoneNumberInput from "../../uicomponents/PhoneNumberInput";
import Create1 from "../../assets/img/Create1.png";

const Contact = () => {
  const [values, setValues] = useState({
    requirement: "",
    name: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState({
    requirement: "",
    name: "",
    email: "",
    phone: "",
  });
  const [showThankYou, setShowThankYou] = useState(false);
  const [isMagic, setIsMagic] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsMagic((prev) => !prev);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleChange = (key, value) => {
    if (error[key]) {
      setError((prev) => ({
        ...prev,
        [key]: "",
      }));
    }
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = () => {
    let hasError = false;
    const newError = { ...error };

    // Check if phone number is filled
    if (!values.phone) {
      newError.phone = "Phone number is required.";
      hasError = true;
    } else {
      newError.phone = "";
    }

    // Check if requirement is selected
    if (!values.requirement) {
      newError.requirement = "This field is required.";
      hasError = true;
    } else {
      newError.requirement = "";
    }

    setError(newError);

    if (!hasError) {
      console.log("form submitted", values);
      setShowThankYou(true);

      // Reset form after submission
      setValues({
        requirement: "",
        name: "",
        email: "",
        phone: "",
      });

      // Hide thank you message after 3 seconds
      setTimeout(() => {
        setShowThankYou(false);
      }, 3000);
    }
  };

  return (
    <div className="bg-[#538CFF] p-5 lg:p-10 my-10 relative overflow-x-hidden">
      <div className="w-full text-center mb-8 lg:mb-12 flex flex-col justify-center items-center">
        <img src={Create1} alt="create" className=" h-[5vh] xl:h-[6vh] mb-4" />
        <span className="text-[#FFFFFF] text-2xl lg:text-5xl font-Outfit break-words">
          Building tech is hard, but with us,{" "}
          {isMagic ? "it's Magic" : "Es Magico"}
        </span>
      </div>

      <div className="">
        <div className="w-full md:mt-[72px] flex flex-wrap gap-6 lg:gap-14 justify-center items-center mb-6">
          <div className="w-[100%] lg:max-w-[460px]">
            <div className="bg-[#5A91FF] rounded-md border-b-2 border-white">
              <select
                className="block bg-[#5A91FF] text-white w-full pl-[14px] py-[18px] cursor-pointer text-[18px] border-white focus:outline-none focus:ring-0"
                value={values.requirement}
                onChange={(e) => handleChange("requirement", e.target.value)}
                style={{
                  backgroundColor: "#5A91FF",
                  color: values.requirement === "" ? "#FFFFFF" : "#FFFFFF",
                }}
              >
                <option
                  value=""
                  disabled
                  hidden
                  style={{
                    color: "#FFFFFF",
                  }}
                >
                  I Want To
                </option>
                <option
                  value="build-a-product"
                  style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
                >
                  Build a Product
                </option>
                <option
                  value="get-hired"
                  style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
                >
                  Get Hired
                </option>
                <option
                  value="leverage-saas"
                  style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
                >
                  Leverage SaaS
                </option>
                <option
                  value="consult-expert"
                  style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
                >
                  Consult an Expert
                </option>
                <option
                  value="leverage-ai-solutions"
                  style={{ color: "#000000", backgroundColor: "#FFFFFF" }}
                >
                  Leverage AI Solutions
                </option>
              </select>
            </div>
            {/* Display error message directly below the select */}
            {error.requirement && (
              <div className="text-[#FF0000] text-[12px] mt-1">
                {error.requirement}
              </div>
            )}
          </div>

          <div className="w-[100%] lg:max-w-[460px]">
            <PhoneNumberInput
              value={values.phone}
              onchange={(value) => handleChange("phone", value)}
            />
            {/* Display error message directly below the phone input */}
            {error.phone && (
              <div className="text-[#FF0000] text-[12px] mt-1">
                {error.phone}
              </div>
            )}
          </div>
        </div>

        {/* <button
          onClick={handleSubmit}
          className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit 
          text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF] 
          rounded-3xl flex justify-center items-center hover:opacity-95 transition 
          duration-300 ease-in-out m-auto gap-6"
        >
          Submit
        </button> */}
        <button
          onClick={() =>
          (window.location.href =
            "mailto:shivanipatil786@gmail.com?subject=Form Submission&body=Your form was submitted!")
          }
          className="w-11/12 sm:w-48 p-2 bg-[#FFFFFF] font-Outfit 
   text-[18px] text-[#0F0F0F] font-semibold placeholder:text-[#FFFFFF] 
   rounded-3xl flex justify-center items-center hover:opacity-95 transition 
  duration-300 ease-in-out m-auto gap-6"
        >
          Submit
        </button>

        {/* Thank you message placed below the submit button */}
        {showThankYou && (
          <div className="mt-4 text-center text-[#FFFFFF]  p-4 rounded-md">
            Thank you for your submission!
          </div>
        )}
      </div>
    </div>
  );
};

export default Contact;
