import React, { useEffect, useRef } from "react";
import saveIcon from "../../assets/svg/saveIcon.svg";
import { motion, useAnimation, useInView } from "framer-motion";

const DigitalExpectational = () => {
  const windowWidth = document.documentElement.clientWidth;
  const borderRef1 = useRef(null);
  const borderRef2 = useRef(null);
  const borderRef3 = useRef(null);
  const borderRef4 = useRef(null);
  const borderRef5 = useRef(null);

  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const textRef4 = useRef(null);
  const textRef5 = useRef(null);

  const borderControl1 = useAnimation();
  const borderControl2 = useAnimation();
  const borderControl3 = useAnimation();
  const borderControl4 = useAnimation();
  const borderControl5 = useAnimation();

  const textControl1 = useAnimation();
  const textControl2 = useAnimation();
  const textControl3 = useAnimation();
  const textControl4 = useAnimation();
  const textControl5 = useAnimation();

  const isInViewBorder1 = useInView(borderRef1, { triggerOnce: true });
  const isInViewBorder2 = useInView(borderRef2, { triggerOnce: true });
  const isInViewBorder3 = useInView(borderRef3, { triggerOnce: true });
  const isInViewBorder4 = useInView(borderRef4, { triggerOnce: true });
  const isInViewBorder5 = useInView(borderRef5, { triggerOnce: true });

  const isInViewText1 = useInView(textRef1, { triggerOnce: true });
  const isInViewText2 = useInView(textRef2, { triggerOnce: true });
  const isInViewText3 = useInView(textRef3, { triggerOnce: true });
  const isInViewText4 = useInView(textRef4, { triggerOnce: true });
  const isInViewText5 = useInView(textRef5, { triggerOnce: true });

  useEffect(() => {
    if (isInViewBorder1) {
      borderControl1.start({
        opacity: 1,
        transition: { duration: 10 },
      });
    }
  }, [isInViewBorder1, borderControl1]);

  useEffect(() => {
    if (isInViewBorder2) {
      borderControl2.start({
        opacity: 1,
        transition: { duration: 10 },
      });
    }
  }, [isInViewBorder2, borderControl2]);

  useEffect(() => {
    if (isInViewBorder3) {
      borderControl3.start({
        opacity: 1,
        transition: { duration: 10 },
      });
    }
  }, [isInViewBorder3, borderControl3]);

  useEffect(() => {
    if (isInViewBorder4) {
      borderControl4.start({
        opacity: 1,
        transition: { duration: 10 },
      });
    }
  }, [isInViewBorder4, borderControl4]);

  useEffect(() => {
    if (isInViewBorder5) {
      borderControl5.start({
        opacity: 1,
        transition: { duration: 10 },
      });
    }
  }, [isInViewBorder5, borderControl5]);

  useEffect(() => {
    if (isInViewText1) {
      textControl1.start({
        x: 0,
        transition: { duration: 1 },
      });
    }
  }, [isInViewText1, textControl1]);

  useEffect(() => {
    if (isInViewText2) {
      textControl2.start({
        x: 0,
        transition: { duration: 1 },
      });
    }
  }, [isInViewText2, textControl2]);

  useEffect(() => {
    if (isInViewText3) {
      textControl3.start({
        x: 0,
        transition: { duration: 1 },
      });
    }
  }, [isInViewText3, textControl3]);

  useEffect(() => {
    if (isInViewText4) {
      textControl4.start({
        x: 0,
        transition: { duration: 1 },
      });
    }
  }, [isInViewText4, textControl4]);

  useEffect(() => {
    if (isInViewText5) {
      textControl5.start({
        x: 0,
        transition: { duration: 1 },
      });
    }
  }, [isInViewText5, textControl5]);

  return (
    <div>
      <div className=" mx-auto mt-[88px]  pl-5 pr-5">
        <div className=" w-[80%] m-auto md:w-[100%] text-[20px] md:text-[38px] text-center mt-[40px] font-[600] md:leading-33.6  leading-22.4">
          Delivering Exceptional Digital Experiences
        </div>
        <div className="  md:ml-[180px] md:mr-[130px] text-[15px] md:text-[19px] md:text-center text-center font-[400] px-[17px] md:w-[70%] ml-[30px] text-[#6F6F6F] mt-[12px] md:mt-[18px]">
          Leverage our expertise in AI, data solutions, enterprise systems, and
          emerging technologies to propel your business forward and redefine customer engagement.
        </div>
        <div className="flex flex-col md:flex-row md:gap-[50px] mt-[40px] md:mt-[115px] p-[16px] md:p-[0px] ">
          <div className="md:w-[40%]">
            <div className="flex justify-center  md:justify-end">
              <img src={saveIcon} alt="icon" />
            </div>
            {windowWidth > 768 && (
              <motion.div
                ref={borderRef1}
                initial={{ opacity: 0 }}
                animate={borderControl1}
              >
                <div className="bg-[#0F0F0F] h-[1px] mt-[6px]"></div>
              </motion.div>
            )}
          </div>
          <div className=" md:w-[50%]">
            <motion.div
              ref={textRef1}
              initial={{ x: 30 }}
              animate={textControl1}
            >
              <div className="text-[16px] md:text-[26px] text-center md:text-left font-semibold mt-[12px]">
                Tailored AI & Data Solutions
              </div>
              <div className="  ml-[16px] mr-[16px] sm:ml-[0px] sm:mr-[0px] text-[15px] md:text-[20px] font-normal md:text-left text-justify text-[#7A7A7A] mt-[16px] leading-7">
                Unlock the potential of AI with our tailore  solutions in machine learning, big data analytics, & data management. From multimodal AI chatbots & generative AI development to GPT integration
                and LLM development, we transform your business with insightful, data-driven results.
              </div>
            </motion.div>
          </div>
        </div>
        <div className="flex flex-col-reverse md:flex-row md:gap-[50px] mt-[24px] md:mt-[115px] justify-end p-[16px] md:p-[0px] ">
          <div className=" md:w-[50%]">
            <motion.div
              ref={textRef2}
              initial={{ x: -30 }}
              animate={textControl2}
            >
              <div className="text-[16px] md:text-[26px] text-center md:text-left font-semibold mt-[12px]">
                Emerging Software Solutions
              </div>
              <div className=" ml-[16px] mr-[16px] sm:ml-[0px] sm:mr-[0px] text-[15px] md:text-[20px] font-normal md:text-left text-justify text-[#7A7A7A] mt-[16px] leading-7">
                Develop custom software solutions that align with your core business, boost customer engagement, and accelerate time-to-market. Our offerings include flexible omnichannel platforms and secure end-to-end solutions tailored to the digital landscape.
              </div>
            </motion.div>
          </div>
          <div className=" md:w-[40%]">
            <div className="flex justify-center md:justify-start">
              <img src={saveIcon} alt="icon" />
            </div>
            {windowWidth > 768 && (
              <motion.div
                ref={borderRef2}
                initial={{ opacity: 0 }}
                animate={borderControl2}
              >
                <div className="bg-[#0F0F0F] h-[1px] mt-[6px]"></div>
              </motion.div>
            )}
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-[50px] mt-[24px] md:mt-[115px] p-[16px] md:p-[0px] ">
          <div className="md:w-[40%]">
            <div className="flex justify-center md:justify-end">
              <img src={saveIcon} alt="icon" />
            </div>
            {windowWidth > 768 && (
              <motion.div
                ref={borderRef3}
                initial={{ opacity: 0 }}
                animate={borderControl3}
              >
                <div className="bg-[#0F0F0F] h-[1px] mt-[6px]"></div>
              </motion.div>
            )}
          </div>
          <div className=" md:w-[50%]">
            <motion.div
              ref={textRef3}
              initial={{ x: 30 }}
              animate={textControl3}
            >
              <div className="text-[16px] md:text-[26px] text-center md:text-left font-semibold mt-[12px]">
                CX, Product, Business & Services Design
              </div>
              <div className=" ml-[16px] mr-[16px] sm:ml-[0px] sm:mr-[0px] text-[15px] md:text-[20px] font-normal md:text-left text-justify text-[#7A7A7A] mt-[16px] leading-7">
                Elevate customer experiences and drive business success with our
                strategic, customer- centric design services. We focus on
                creating innovative solutions that address user needs, foster
                engagement, and align with your organizational goals.
              </div>
            </motion.div>
          </div>
        </div>
        <div className="flex md:gap-[50px] flex-col-reverse md:flex-row mt-[24px] md:mt-[115px] justify-end p-[16px] md:p-[0px]">
          <div className=" md:w-[50%]">
            <motion.div
              ref={textRef4}
              initial={{ x: -30 }}
              animate={textControl4}
            >
              <div className="text-[16px] md:text-[26px] text-center md:text-left font-semibold mt-[12px]">
                Engineering & Enablement
              </div>
              <div className="ml-[16px] mr-[16px] sm:ml-[0px] sm:mr-[0px] text-[15px] md:text-[20px] font-normal md:text-left text-justify text-[#7A7A7A] mt-[16px] leading-7  ">
                Transform your operations and maximize performance through our
                comprehensive engineering solutions. From cloud migration and
                agile transformation to integration strategy, DevOps automation,
                and robust cybersecurity measures, we empower your business with
                the tools needed for sustainable growth and efficiency.
              </div>
            </motion.div>
          </div>
          <div className=" md:w-[40%]">
            <div className="flex justify-center md:justify-start">
              <img src={saveIcon} alt="icon" />
            </div>
            {windowWidth > 768 && (
              <motion.div
                ref={borderRef4}
                initial={{ opacity: 0 }}
                animate={borderControl4}
              >
                <div className="bg-[#0F0F0F] h-[1px] mt-[6px]"></div>
              </motion.div>
            )}
          </div>
        </div>
        <div className="flex p-[16px] md:p-[0px] flex-col md:flex-row md:gap-[50px] my-[24px] md:my-[115px] ">
          <div className=" md:w-[40%]">
            <div className="flex justify-center md:justify-end">
              <img src={saveIcon} alt="icon" />
            </div>
            {windowWidth > 768 && (
              <motion.div
                ref={borderRef5}
                initial={{ opacity: 0 }}
                animate={borderControl5}
              >
                <div className="bg-[#0F0F0F] h-[1px] mt-[6px]"></div>
              </motion.div>
            )}
          </div>
          <div className=" md:w-[50%]">
            <motion.div
              ref={textRef5}
              initial={{ x: 30 }}
              animate={textControl5}
            >
              <div className="text-[16px] md:text-[26px] text-center md:text-left font-semibold mt-[12px]">
                Scaling Proven Businesses through Innovation
              </div>
              <div className=" ml-[16px] mr-[16px] sm:ml-[0px] sm:mr-[0px] text-[15px] md:text-[20px] font-normal md:text-left text-justify text-[#7A7A7A] mt-[16px] leading-7">
                Transform your operations with cutting-edge technology. Our expertise in digital transformation empowers you to optimize systems, migrate to modern platforms, streamline processes, enhance security, and unlock new growth opportunities. By modernizing your systems, you can expand your business, scale operations, and achieve long-term success.
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalExpectational;
