import React from 'react';
import Group from '../../assets/img/Group.png';
import Datadrivendecision from "../../assets/img/Datadrivendecision.png";
import easymanagement from "../../assets/img/easymanagement.png";
import personalizedoffers from "../../assets/img/personalizedoffers.png";

const FeaturesSection = () => {
    return (
        <>
            {/* Interactive Journeys Section */}
            <div className="my-8">
                <div className="flex flex-col md:flex-row  md:ml-[108px] md:mr-[108px] items-center justify-center  gap-8  ">
                    <div className=" flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mt-[83.88px]  md:mb-[147px] gap-[8px] md:gap-[23px]">
                        <p className=" text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Interactive Journeys
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed ">
                            Engage users with fun elements like scratch cards! They’ll keep coming back for more.
                        </p>
                    </div>
                    <div className=" relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[303px]  rounded-lg overflow-hidden  mb-[65.13px] md:ml-[108px]  md:mt-[72px] pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={Group} alt="Group" className=" object-cover" />
                    </div>
                </div>
            </div>

            {/* Data Driven Section */}
            <div className="my-8">
                <div className="mt-40px   md:mr-[108px] items-center justify-center flex flex-col flex-col-reverse md:flex-row gap-8 ">
                    <div className=" relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[303px]  rounded-lg overflow-hidden  mb-[65.13px] md:ml-[108px]  md:mt-[72px] pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={Datadrivendecision} alt="Data Driven Decisions" className=" object-cover" />
                    </div>
                    <div className="flex flex-col  justify-center ml-[26px] mr-[26px] mt-40px md:mt-[83.88px]  md:mb-[147px] gap-[8px] md:ml-[108px] md:gap-[23px]">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Data-Driven Decisions
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            We give you a powerful dashboard to see what's working and what's not. Make informed choices to keep your users hooked.
                        </p>
                    </div>
                </div>
            </div>

            {/* Easy Management Section */}
            <div className="my-8">
                <div className="flex flex-col md:flex-row  mt-40px  md:ml-[108px] md:mr-[108px] items-center justify-center flex flex-col gap-8 ">
                    <div className=" flex flex-col justify-center ml-[26px] mr-[26px] mt-40px md:mt-[83.88px]  md:mb-[147px] gap-[8px] md:gap-[23px]">
                        <p className="text-xl md:text-4xl text-center md:text-start text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Easy Management
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Create and manage offers in a breeze! See it Live! with interactive previews before launching. Set permissions for your team to keep things organized.
                        </p>
                    </div>
                    <div className="relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[303px]  rounded-lg overflow-hidden  mb-[65.13px] md:ml-[108px]  md:mt-[72px] pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={easymanagement} alt="easymanagement" className="  object-cover" />
                    </div>
                </div>
            </div>

            {/* Personalised Offers Section */}
            <div className="  my-8">
                <div className=" flex flex-col md:flex-row  flex-col-reverse mt-40px  md:mr-[108px] items-center justify-center flex flex-col gap-8  flex flex-col justify-center md:flex-row gap-8 ">
                    <div className="relative justify-center w-[243px] h-[136.69px] md:w-[540px] md:h-[303px]  rounded-lg overflow-hidden  mb-[65.13px] md:ml-[108px]  md:mt-[72px] pb-6 md:mb-[72px] max-w-[540px] max-h-[303.75]">
                        <img src={personalizedoffers} alt="personalizedoffers" className="object-cover" />
                    </div>
                    <div className="flex flex-col justify-center item-center md:text-start md:items-start  md:ml-[108px] ml-[26px] mt-[40px]  md:mb-[147px] gap-[8px] md:gap-[23px] pr-10">
                        <p className="text-xl md:text-4xl text-center text-#0F0F0F font-outfit font-bold md:leading-[50.4px]">
                            Personalized Offers
                        </p>
                        <p className="text-sm  max-w-[540px] text-center md:text-start md:text-2xl text-[#7A7A7A] font-outfit  leading-relaxed">
                            Craft special rewards for specific products or user groups. Everyone feels valued!
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeaturesSection;
