import React from 'react';
import DominosPizza from '../../assets/img/DominosPizza.png';
import plum1 from '../../assets/img/plum1.png';
import TATANeu from '../../assets/img/TATANeu.png';
import peekup1 from '../../assets/img/peekup1.png';
import Bookmyshow from '../../assets/img/Bookmyshow.png';
import Samagra1 from '../../assets/img/Samagra1.png';

const GratifySection = () => {
    return (
        <div className="mx-auto ">
            <div className=" md:mt-[44.16px]  md:mb-[45.02px] flex flex-col items-center justify-center ">
                <div className="flex flex-col justify-center items-center text-center gap-[36px] mt-[44.73px]">
                    <p className="font-outfit text-[24px] md:text-[50px]  font-semibold leading-[63px] text-[#0F0F0F]">
                        Who loves Gratify?
                    </p>
                    <p className="font-outfit text-[16px] md:text-[24px] md:text-start text-center font-normal leading-[30.24px] text-left text-[#7A7A7A]">
                        See how leading companies are using Gratify to create happy and engaged user experiences!
                    </p>
                </div>
                <div className="flex mt-[40px]  mb-[45px] items-center justify-center">
                    <img
                        src={DominosPizza}
                        alt="Dominos Pizza"
                        className="md:w-[135px] md:h-[90px] mr-[10px] md:mr-[54px] object-cover overflow-hidden"
                    />
                    <img
                        src={plum1}
                        alt="Plum"
                        className="md:w-[135px] md:h-[90px] mr-[10px] md:mr-[54px] object-cover overflow-hidden"
                    />
                    <img
                        src={TATANeu}
                        alt="TATA Neu"
                        className=" md:w-[135px] md:h-[90px] mr-[10px] md:mr-[54px] object-cover overflow-hidden"
                    />
                    <img
                        src={peekup1}
                        alt="Peekup"
                        className=" md:w-[135px] md:h-[90px] mr-[10px] md:mr-[54px] object-cover overflow-hidden"
                    />
                    <img
                        src={Bookmyshow}
                        alt="Bookmyshow"
                        className=" md:w-[135px] md:h-[90px] mr-[10px] md:mr-[54px] object-cover overflow-hidden"
                    />
                    <img
                        src={Samagra1}
                        alt="Samagra"
                        className=" md:w-[135px] md:h-[90px] object-cover overflow-hidden"
                    />
                </div>
            </div>
            <div className="mx-auto flex justify-center ">
                <div className="flex  justify-center    mt-[72px] mb-[45px]">
                    <p className="text-center font-outfit text-[24px] md:text-[50px] font-bold leading-[63px] text-[#0F0F0F] ">
                        Let’s dive deeper!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default GratifySection;
