import React from "react";
import TopHeading from "../components/products/TopHeading";
import SoftwareAsService from "../components/home/SoftwareAsService";

const Products = () => {
  return (
    <div>
      <TopHeading />
      <div className="w-[90%] m-auto">
        <SoftwareAsService />
      </div>
    </div>
  );
};

export default Products;
