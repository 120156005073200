import React from "react";
import { motion } from "framer-motion";

const TopHeading = () => {
  return (
    <div className="bg-[#FFFFFF] lg:h-screen mb-10 pb-20 lg:pb-0 relative overflow-hidden">
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{
          delay: 0.5,
          duration: window.innerWidth <= 780 ? 1.2 : 0.9,
        }}
      >
        <h1 className="font-light text-4xl lg:text-8xl pt-36 lg:pt-52 text-center">
          Don’t just catch-up to <span className="font-bold">tech,</span> 
        </h1>
        <h1 className="font-light text-4xl lg:text-8xl text-center mb-5 lg:mb-12">
          <span className="font-bold"></span>disrupt!
        </h1>
      </motion.div>
      <motion.div
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        transition={{ delay: 2.3, duration: 1 }}
      >
        <p className="text-center font-normal text-base lg:text-3xl hidden lg:block">
          We are here to enable you. Let go for the tech reins.
        </p>
        <p className="text-center font-normal text-base lg:text-3xl hidden lg:block">
          we've got the wheel
        </p>

        <p className="text-center font-normal text-base lg:text-3xl block lg:hidden">
          We leverage AI as an enabler, building
        </p>
        <p className="text-center font-normal text-base lg:text-3xl block lg:hidden">
          advanced tech that brings your ideas to life.
        </p>
      </motion.div>
    </div>
  );
};

export default TopHeading;
